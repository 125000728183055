<template>
  <v-form ref="formIngresso" v-model="validFormIngresso">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg6 xl4>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                label="Sede*"
                :items="sedi"
                v-model="ingresso.sede"
                :disabled="readOnly()"
                :rules="[$rules.required]" />
              <date-picker
                v-model="ingresso.data"
                :readonly="readOnly()"
                :rules="[$rules.required]"
                :max="oggi"
                label="Data *"
                name="data" />
              <v-select
                label="Cliente*"
                :items="clienti"
                item-text="ragione_sociale"
                item-value="cliente_id"
                :rules="[$rules.required]"
                v-model="ingresso.cliente_id"
                :disabled="readOnly()"
                @input="changedCliente" />
              <v-select
                label="Provenienza*"
                :items="destinazioni"
                item-text="presso"
                item-value="destinazione_id"
                v-model="ingresso.destinazione_id"
                :disabled="readOnly()"
                :rules="[$rules.required]" />
              <v-text-field
                label="Identificativo ingresso"
                v-model="ingresso.identificativo"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(20)]" />
              <v-text-field
                label="OT"
                v-model="ingresso.ot"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(20)]" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <v-btn
            class="ml-0"
            color="primary"
            :disabled="!validFormIngresso || readOnly()"
            @click.native="save()">
            Salva
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'

export default {
  props: {
    ingresso: Object
  },
  data: () => ({
    validFormIngresso: false,
    confirm: false,
    clienti: [],
    destinazioni: [],
    destNonAnag: false,
    oggi: new Date().toISOString().split('T')[0],
    sedi: [{
      value: 1,
      text: 'Cormano'
    }, {
      value: 2,
      text: 'Paderno'
    }]
  }),
  components: {
    datePicker
  },
  watch: {
    destNonAnag: function (newVal, oldVal) {
      if (newVal) {
        this.ingresso.cliente_id = null
        this.ingresso.destinazione_id = null
      } else {
        this.ingresso.destinazione = null
      }
    }
  },
  methods: {
    save () {
      if (this.$refs.formIngresso.validate()) {
        this.$plsqlSave('ingresso', this.ingresso)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Ingresso salvato'
            })
            if (this.$route.params.id === 'add') {
              $EventBus.$emit('ingresso:added', response.data)
            }
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio ingresso' })
          })
      }
    },
    getClienti (ingressoId) {
      this.$plsqlMethod('ordine', 'get_clienti', { ingresso_id: ingressoId })
        .then(response => {
          let t = this
          t.clienti = response.data
          if (t.ingresso._id) {
            if (t.ingresso.destinazione_id) {
              let cliente = t.clienti.find(function (c) { return c.cliente_id === t.ingresso.cliente_id })
              t.destinazioni = cliente.destinazioni
            } else {
              t.destNonAnag = true
            }
          }
          // tipo 2 o 1
        })
        .catch(err => {
          console.log('zzz', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
        })
    },
    readOnly () {
      return !!(this.ingresso._id && this.ingresso.inviato)
    },
    changedCliente () {
      let idc = this.ingresso.cliente_id
      let cliente = this.clienti.find(function (c) { return c.cliente_id === idc })
      this.destinazioni = cliente.destinazioni
      if (this.destinazioni.length === 1) {
        this.ingresso.destinazione_id = this.destinazioni[0].destinazione_id
      } else {
        delete this.ingresso.destinazione_id
      }
    }
  },
  mounted () {
    // this.getClienti()
  }
}
</script>
