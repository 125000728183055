<template>
  <v-dialog
    v-model="ingresso"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false"
    scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3" v-if="ingresso">
        <v-toolbar-title>
          <span v-if="ingresso._id">{{ ingresso.identificativo }}</span>
          <span v-if="!ingresso._id">Nuovo Ingresso</span>
          <item-updated
            v-if="ingresso._id"
            :mdate="ingresso.modifica"
            :muser="ingresso.utente" />
          </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <semaphore slot="activator" :stato="ingresso.inviato ? 0 : 2" />
          {{ !ingresso.inviato ? 'Non ancora inviato alla gestione' : 'Già inviato alla gestione'}}
        </v-tooltip>
        <v-btn icon @click="$router.push({ name: 'ingressi' })"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-stepper non-linear light v-model="step">
          <v-stepper-header v-show="ingresso._id">
            <template v-for="(step, index) in steps">
              <v-stepper-step
                color="primary"
                :key="index + 'step'"
                :step="step.number"
                @click.native="changeStep(index, $event)"
                editable>{{ step.name }}</v-stepper-step>
              <v-divider :key="index + 'divider'" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <!-- primo step direttamente qui -->
              <ingresso-scheda ref="scheda" :ingresso="ingresso" />
            </v-stepper-content>
            <v-stepper-content v-for="(s, index) in steps" :key="index" :step="s.number" v-if="s.number > 1">
              <!-- gli altri step sono rotte figlie -->
              <router-view v-if="s.number === step" :ingresso="ingresso" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="ingresso._id">
        <v-container fluid>
          <v-layout>
            <v-flex>
              <template v-for="(s, index) in steps">
                <div v-if="s.number === step" :key="index">
                  <span class="mr-4" v-if="steps[index - 1]">
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index - 1)">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index - 1].name }}
                    </span>
                  </span>
                  <span class="ml-4" v-if="steps[index + 1]">
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index + 1].name }}
                    </span>
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index + 1)">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </span>
                </div>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import itemUpdated from '@/components/common/ItemUpdated'
import ingressoScheda from '@/components/magazzino/ingressi/IngressoScheda'
import Semaphore from '@/components/common/Semaphore'
export default {
  data: () => ({
    ingresso: {},
    step: 1,
    steps: [
      { number: 1, name: 'Informazioni generali', route: 'ingresso' },
      { number: 2, name: 'Lavorazione', route: 'ingressoLavorazione' },
      { number: 3, name: 'Bancali', route: 'ingressoBancali' }
    ]
  }),
  components: {
    itemUpdated,
    ingressoScheda,
    Semaphore
  },
  methods: {
    changeStep (index) {
      this.$router.push({ name: this.steps[index].route, params: { id: this.ingresso._id } }) // prima vai in modo che la rotta di arrivo inizi già a leggere i dati
      this.step = this.steps[index].number
    }
  },
  mounted () {
    if (this.$route.params.id !== 'add') {
      this.$plsqlRead('ingresso', this.$route.params.id)
        .then(response => {
          this.ingresso = response.data
          this.$refs.scheda.getClienti(response.data._id)
          if (this.$route.name !== 'ingresso') { // arrivo diretto al path di uno step
            this.step = this.steps.find(step => step.route === this.$route.name).number
          }
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore lettura ingresso'
          })
        })
    } else {
      this.ingresso = {
        data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
      }
      this.$refs.scheda.getClienti()
    }
    $EventBus.$on('ingresso:added', (ingresso) => {
      this.ingresso = ingresso
      this.$router.push({ name: 'ingresso', params: { id: ingresso._id } })
    })
  }
}
</script>
