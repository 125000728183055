import { render, staticRenderFns } from "./IngressoScheda.vue?vue&type=template&id=155fd3af&"
import script from "./IngressoScheda.vue?vue&type=script&lang=js&"
export * from "./IngressoScheda.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports